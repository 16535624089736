import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const LegalPage = () => (
  <Layout>
    <SEO title="Legal notice" />

<div className="container mt-5">
  <h1>Legal notice · Mentions légales</h1>
</div>
<p>&nbsp;</p>
<div class="container">
<h2><strong>NEWPRICER</strong></h2>
<p>This Website is published by NEWPRICER SAS, a French law company with a share capital of 30.000 euros and registered at Company Registration Office of Bordeaux under the number 854 098 605.</p>
<p>NEWPRICER’s head office is located 2 Rue Doyen Georges Brus 33600 Pessac</p>

<p>Publication Director (legal representative): Gael Lebreton</p>
<p>This Website is hosted by: Netlify Inc., 2325 3rd Street, San Francisco, CA.</p>
<p>&nbsp;</p>

<h2><strong>Terms of use</strong></h2>
<p><strong>1.           Disclaimer</strong><br />
NEWPRICER does not guarantee that the website https://www.newpricer.com/ (“the Website”) is free of errors, bugs or virus that the Website performs without interruptions or breakdowns. Therefore, NEWPRICER shall not be held liable for any interruption of the Website.</p>
<p>NEWPRICER declines all liability of any kind for direct, indirect or incidental damages, loss of data or profit associated resulting from the use of the Website and its content.</p>
<p>&nbsp;</p>
<p><strong>2.           Intellectual Property</strong><br />
The technology Solutions developed and/or licensed by NEWPRICER (“Solutions”) and content of this Website, including but without limitation, its architecture, visual identity as well as all the information and elements available such as texts, articles, brochures, photographs, demo videos, images, brands, logos, trademarks, services, products and company name, software, tools, algorithms, process or technologies (“the Content”) is protected by applicable legislation governing copyright, trademark rights, design rights and unfair competition law.</p>
<p>&nbsp;</p>
<p>The Solutions and Content are and shall remain the sole and exclusive property of NEWPRICER and/or its contributors as the case may be.</p>
<p>No right to use or reproduce the Content is granted hereunder. No license or authorization whatsoever is given with to the Content or any material covered in this Website.</p>
<p>NEWPRICER remains the sole owner of the intellectual property rights related to the Solutions including any modification, enhancement, development or new version thereof.</p>
<p><strong>3.           Privacy Statement</strong><br />
Data Protection Regulation means together the French data protection law of January 6, 1978, and the General Regulation 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (“the GDPR”).</p>
<p>For the purpose of this article, the terms “personal data”, “process, “processing”, “data subject”, “controller”, “processor”, “sub-processor” shall have the meaning set forth in the Data Protection Regulation.</p>
<p><strong>3.1       Collection and Processing</strong></p>
<p>The performance of our services and the use of our websites and/or Solution(s) involves the processing of personal data, in particular our client’s personal data, for the purpose of managing Clients and prospects. For example, our Clients may be called upon to provide personal data (such as name, email, company name…) for the purpose of creating a client’s account and be granted access to our Solutions.</p>
<p>In this context, the client is informed that the personal data may be processed, stored and accessed by NEWPRICER’s authorized staff and by third parties such as sub-processors, contractors, services providers involved in the performance our services and the management of the Solution(s), the Website or the Content. Prior to sharing any of our clients personal data with a sub-processor, NEWPRICER makes sure that the sub-processor offers an appropriate level of security and processes the personal data in compliance with the Data Protection Regulation.</p>
<p><strong>3.2       Security</strong></p>
<p>Whether NEWPRICER acts as data controller or a data processor, NEWPRICER undertakes to take technical and organizational measures to ensure the security of personal data.</p>
<p>More generally, NEWPRICER ensures that clients personal data are stored safely, no longer than necessary and protected against accidental or illegal loss, abuse, changes, leak or unauthorized access.</p>
<p><strong>3.3       Confidentiality</strong></p>
<p>Client’s information and personal data remains confidential. NEWPRICER ensures that clients information and personal data are only processed by authorized staff who need access to it to fulfill their duties.</p>
<p>It is forbidden for the staff to use clients information or personal data for their own private purposes or to make it accessible to unauthorized persons or entities.</p>
<p><strong>3.4       Rights</strong></p>
<p>As a data subject and in accordance with Data Protection Regulation, you have the right to access, rectify, delete any of their personal data. You also have the right to portability in the conditions provided by the GDPR.</p>
<p>Data subjects can exercise their rights at any time by sending an email to <a href="mailto: newpricer <contact@newpricer.com>">newpricer</a>. NEWPRICER undertakes to reply within one (1) month following the reception of your demand. That period may be extended by two (2) further months where necessary, taking into account the complexity and the number of requests. NEWPRICER will inform you of any such extension within one (1) month of receipt of the request</p>
<p>In the event of any doubt regarding the identity of the author of the demand, NEWPRICER reserves the right to ask for any further identification element (such as a copy of I.D).</p>
<p>Any dispute in connection with the processing of your personal data shall be notified to NEWPRICER by registered letter (with return receipt requested) or by email (<a href="mailto: newpricer <contact@newpricer.com>">newpricer</a>) indicating the reasons for the dispute, in order to find an amicable solution.</p>
<p>If no an amicable solution can be found within thirty (30) days of the notification of the dispute, you may file a complaint before the CNIL (French supervisory authority).</p>
<p><strong>4.           Cookies</strong><br />
NEWPRICER may use cookies on the Website. All information about cookies are available at all time in our Privacy Policy.</p>
<p><strong>5.           Jurisdiction &#8211; Governing law</strong><br />
These Terms of Use shall be governed by the laws of France. Any matter, conflict or claim arising from the herein Terms of Use shall be submitted to the French competent courts of Paris.</p>
<p>&nbsp;</p>
<p>Last update : November 15, 2020</p>
</div>





  </Layout>
)

export default LegalPage
